import React, { useEffect } from "react";
import './App.css';
import 'react-tabs/style/react-tabs.css';
import Developeractivitycuate from './assets/Developeractivitycuate.webp';
import industries from './industries.json';
import keybenifit from './keybenifit.json';
import howitwork from './howitwork.json';
import HowWeWork from './assets/webengineering2.webp';
import NodeJs from './assets/NodeJs.png';
import Angular from './assets/Angular.png';
import ReactJs from './assets/ReactJs.png';
import Shopify from './assets/Shopify.png';
import Wodpress from './assets/Wodpress.png';
import MetaTags from 'react-meta-tags';
import {  scrollToTop } from "./App";
import { Link } from 'react-router-dom';
import ContactForm from './ContactForm';
import WebDevlopmentFaq from './WebDevlopmentFaq';
import Client from './client';
import CaseStudies from './CaseStudies';
import Marquee from 'react-fast-marquee';
import marquee from "./merquee.json";
function WebappDevelopment() {
    useEffect(() => {
        scrollToTop();
      }, [])
  return (
    <div className="App WebEngineering">
       <MetaTags>
            <meta name="description" content="Build robust web apps with Arccus. We offer expert web application development services for businesses in India & the USA with powerful, scalable solutions." />
            <meta name="keyword" content="web development company, web application development company," />
            <meta property="og:title" content="Web Application Development Company | Arccus Inc" />
            <link rel="canonical" href="https://arccusinc.com/web-development"/>
            <title>Expert Web Application Development Services | Arccus Inc</title>
      </MetaTags>
      <section className='home-banner banner-sec Web-Engineering'>
        <div className='container'>
            <div className='row align-items-center'>
                {/* <div className='col-md-6'>
                    <div className='banner-img text-center'>
                    <img src={webengineering} className="main-banner" alt={"banner"} />
                    </div>
                </div> */}
                <div className='col-12'>
                    <div className='banner-content text-center'>
                        <h1>Top Web <span className='txt-with-bg'>Development</span>  <br/>Services</h1>
                        <p>Custom website design and development solutions to help your business thrive in today’s competitive digital landscape.</p>
                        <Link to="/contact-us" className='quote'>Request A Quote</Link>
                    </div>
                </div>
            </div>
        </div>
      </section>
      <section className='development-service'>
        <div className='container'>
           <div className='row'>
             <div className='col-md-6'>
                <h2 className='text-start pb-0'>Arccus’s Website Development Service <span className='lastword'>#1 in USA</span></h2>
                <p>Arccus is a top web app development services with expertise in the web app development field. Our team of experts is all set to deliver a professional product for you with guaranteed satisfaction at an affordable rate.</p>
             </div>
             <div className='col-md-6'>
                <p>We provide top-tier web app development services tailored to meet your unique business needs. Our expert team specializes in creating custom web solutions that prioritize user experience and performance. With a commitment to quality and client satisfaction, we deliver professional products at competitive rates.
                </p>
                <p>Let us help you enhance your online presence and achieve your business goals.</p>
                <Link to="/contact-us" className='quote mt-5 d-block'>Contact us today</Link>
             </div>
           </div>
        </div>
      </section>
      <section className='DevelopmentService'>
        <div className='container'>
            <div className='row align-items-center'>
                <div className='col-md-6'>
                    <img src={ Developeractivitycuate } alt={"development activity "} />
                </div>
                <div className='col-md-6'>
                    <h2>Your All-in-One<span className='lastword'> Web Solution Awaits!</span></h2>
                    {/* <p>We are dedicated to constantly improving our web development solutions. Our team of skilled developers is committed to staying up-to-date with the latest technologies and trends, ensuring that your project benefits from the best innovations available. </p>
                    <p>We provide a smooth and efficient experience, guiding you from the initial brainstorming stage all the way through to implementation and ongoing maintenance. Here’s what you can expect from our web development solutions:</p> */}
                    <ul>
                        <li>We design features that fit your unique needs perfectly.</li>
                        <li>Our visually appealing UI/UX makes navigation easy and enjoyable for your users.</li>
                        <li>Your solution will be designed to grow alongside your business, with industry-standard security measures in place from day one.</li>
                        <li>Your website will work flawlessly on any device, whether it's a phone, tablet, or desktop.</li>
                        <li>We deliver professional, eye-catching visuals that engage your audience.</li>
                        <li>Our commitment doesn’t end at launch; we offer ongoing maintenance and support to keep your solution running smoothly.</li>
                    </ul>
                </div>
            </div>
        </div>
      </section>
      <section className='industriesserve'>
          <div className='container'>
               <h2 className='line-bottom'>Web Development Services <span className='lastword'>We Offer</span></h2>
               <div className='industry-row'>
                    {industries.map( industries => {
                    return(
                    <div className='indusries-card'>
                        <img src={ industries.icon } alt={"industries"} loading='lazy' />
                        <p>{industries.name}</p>
                        <span>{industries.text}</span>
                    </div>
                    )})} 
               </div>
               <div className="text-center mt-4">
                <Link to="/contact-us" className='quote'>Consult Now!</Link>
               </div>
          </div>
      </section>
      <section className='HowWework '>
          <div className='container'>
              <div className='row align-items-center'>
                  
                        <div className='col-md-6 sub-content'>
                             <h2>How We work as Development Service Provider</h2>
                             <ul>
                                <li>We begin by analysing the specific client requisites and their inimitable requirements for the web development.</li>
                                <li>Our web app development experts leverage the most advanced tools and proven methodologies to shape bespoke solutions.</li>
                                <li>We deliver distinctly superior services and seamless user experience by building robust solutions.</li>
                             </ul>
                        </div>
                        <div className='col-md-6'>
                            <img src={ HowWeWork } alt={"how we work"}  loading='lazy' />
                        </div>
              </div>
          </div>
      </section>
      
      <section className='key-benifit'>
        <div className='container'>
            <h2 className='line-bottom'>Key Benefits Of <span className='lastword'>Choosing Us</span></h2>
            <div className='row'>
               {keybenifit.map( keybenifit => {
                return(
               <div className=' col-md-6'>
                    <div className='key-benifit-cad'>
                        <h5>{ keybenifit.head }</h5>
                        <p>{ keybenifit.about }</p>
                   </div>
               </div>
               )})} 
            </div>
        </div>
      </section>
      <section className='How-It-Work'>
          <div className='container'>
               <h2 className='line-bottom'>How It <span className='lastword'>Work?</span></h2> 
               {howitwork.map( howitwork => {
                return(
               <div className='row how-it-work-row align-items-center'>
                    <div className='col-md-6 text-center'>
                       <img src={ howitwork.image } alt={"how it work"} loading='lazy' />
                    </div>
                    <div className='col-md-6'>
                        <h3>{ howitwork.MainHead }</h3>
                        <h4>{ howitwork.SubHead }</h4>
                        <p>{ howitwork.text }</p>
                    </div>
               </div>
               )})}     
          </div>
      </section>
      <section className='wd-technology'>
        <div className='container'> 
        <h2>
          Technologies <span className="lastword">We Use</span>
        </h2>
        <div className='technology-grid'>
            <div className='technology-item'>
                <img src={Wodpress} alt="wordpress" />
                <p>WordPress</p>
            </div>
            <div className='technology-item'>
                <img src={Shopify} alt="shopify" />
                <p>Shopify</p>
            </div>
            <div className='technology-item'>
                <img src={ReactJs} alt="reactjs" />
                <p>React.js</p>
            </div>
            <div className='technology-item'>
                <img src={Angular} alt="angular" />
                <p>Angular</p>
            </div>
            <div className='technology-item'>
                <img src={NodeJs} alt="nodejs" />
                <p>Node.js</p>
            </div>
        </div>
        <div className="text-center mt-4">
        <Link to="/contact-us" className='quote'>Talk to our team</Link>
        </div>
        </div>
      </section>
      <section className="industries">
        <h2>
          Industries We <span className="lastword">Serve</span>
        </h2>
        <div className="marquee d-block">
          {marquee.map((data, ind) => {
            return (
              <Marquee
                direction={ind === 0 ? "left" : "right"}
                gradient={false}
                pauseOnHover={true}
              >
                {data.data.map((marquee) => {
                  return (
                    <div
                      className="marquee-slider"
                      style={{ background: marquee?.color }}
                    >
                      <div className="marquee-slide">
                        <div className="marquee-img">
                          <img
                            src={marquee.images}
                            width={"50"}
                            height={"50"}
                            alt="marquee"
                            loading="lazy"
                          />
                        </div>
                        <ul>
                          <li className="industries-name">
                            {marquee.industries}
                          </li>
                          <li className="about-industries">{marquee.about}</li>
                        </ul>
                      </div>
                    </div>
                  );
                })}
              </Marquee>
            );
          })}
        </div>
      </section>
      {/* <section className='Tell-Us-About-Your-Project'>
           <div className='container'>
              <h2 className='line-bottom'>Tell Us About Your <span className='lastword'>Project</span></h2>
              <div className='row align-items-center'>
                            <div className='col-md-6'>
                                <img src={ HowWeWork } alt={"how we work"} loading='lazy'/>
                            </div>
                            <div className='col-md-6'>
                                <h3>What will happen next?</h3>
                                <ul>
                                    <li>We’ll reach out to you within 24 hours.</li>
                                    <li>We’ll discuss your project and gather your requirements and business objectives, and develop a proposal accordingly.</li>
                                    <li>You can start 15-days risk-free trial with us.</li>
                                </ul>
                                <Link to="/contact-us" className='quote'>Contact Us</Link>
                            </div>
              </div>
           </div>
      </section>  */}
      <section className='web-development-needs'>
        <div className='container'>
           
            <div className='row'>
                <div className='col-md-6'>
                    <h2 className='text-start'>
                        Why Choose Arccus Inc. for Your <span className="lastword">Web Development</span> Needs? 
                    </h2>
                    <p>We blend creativity, technology, and industry expertise to create dynamic websites that truly connect with your audience.</p>
                    <p>Our comprehensive range of services includes everything from custom web app development to robust enterprise solutions. With an agile development approach, we prioritize timely delivery, high quality, and scalability, ensuring that your website not only meets today’s demands but is also prepared for future growth. </p>
                    <Link to="/contact-us" className='quote mt-4 d-block'>Contact us today</Link>
                </div>
                <div className='col-md-6'>
                     <div className='ar-overview'>
                        <div className='overview-block top-block'>
                            <div className='overview-item'>
                                <h3>10<span className='lastword'>+</span></h3>
                                <p>Years of Experience</p>
                            </div>
                            <div className='overview-item'>
                                <h3>15<span className='lastword'>+</span></h3>
                                <p>Tech Professionals</p>
                            </div>
                            <div className='overview-item'>
                                <h3>20<span className='lastword'>+</span></h3>
                                <p>Web developers</p>
                            </div>
                        </div>
                        <div className='overview-block'>
                            <div className='overview-item'>
                                <h3>98<span className='lastword'>%</span></h3>
                                <p>Customer Retention</p>
                            </div>
                            <div className='overview-item'>
                                <h3>100<span className='lastword'>%</span></h3>
                                <p>Secure Code</p>
                            </div>
                            <div className='overview-item'>
                                <h3>150<span className='lastword'>+</span></h3>
                                <p>Delivered Project</p>
                            </div>
                        </div>
                     </div>
                </div>
            </div>
        </div>
      </section>
      <CaseStudies />
      <section className="client">
        <div className="container">
          <h2>
            Our Happy Clients <span className="lastword">Experience</span>
          </h2>
          <p className="main-content">
            Customer experience during our place, and we share it with you as a
            reference to believe in us.
          </p>
          <Client />
        </div>
      </section>
      <WebDevlopmentFaq />
      <ContactForm />
    </div>
  );
}
export default WebappDevelopment;
