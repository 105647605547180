import banner from "./assets/home.webp";
import bannerbg from "./assets/home-bg.webp";
import CommitmentToExcellence from "./assets/CommitmentToExcellence.png";
import ThrougnUnderstandingOfClientObjectives from "./assets/ThrougnUnderstandingOfClientObjectives.png";
import ResponsibleAndTrustworthly from "./assets/ResponsibleAndTrustworthly.png";
import SecurityAndAlliances from "./assets/SecurityAndAlliances.png";
import CostEffectiveDevelopment from "./assets/CostEffectiveDevelopment.png";
import AgileYetFlexibleDevelopment from "./assets/AgileYetFlexibleDevelopment.png";
import CollaborateWithYouEveryStepOfTheWay from "./assets/CollaborateWithYouEveryStepOfTheWay.png";
import Cimprove from "./assets/Cimprove.jpg";
import LogoIpsum from "./assets/LogoIpsum.png";
import LogoPartner from "./assets/LogoPartner.png";
import LogoIpsum2 from "./assets/LogoIpsum2.png";
import LogoIpsum3 from "./assets/LogoIpsum3.png";
import LogoPartner2 from "./assets/LogoPartner2.png";
import "./App.css";
import Client from "./client.js";
import Project from "./project.js";
import card from "./card.json";
import React, { useState } from "react";
import technology from "./technology.json";
import marquee from "./merquee.json";
import Marquee from "react-fast-marquee";
import { useEffect } from "react";
import { scrollToTop } from "./App";
import "react-tabs/style/react-tabs.css";
import MetaTags from "react-meta-tags";
import { Link } from "react-router-dom";
import ContactForm from "./ContactForm.js";
import CaseStudies from "./CaseStudies.js";
import officelocation from './officelocation.json';
function App() {
  useEffect(() => {
    scrollToTop();
  }, []);

  const Counter = ({ target }) => {
    const [count, setCount] = useState(0);

    useEffect(() => {
      const increment = target / 150;

      const updateCounter = () => {
        setCount((prevCount) => {
          if (prevCount < target) {
            return Math.ceil(prevCount + increment);
          } else {
            return target;
          }
        });
      };

      const interval = setInterval(updateCounter, 15);

      return () => clearInterval(interval);
    }, [target]);

    return <div className="counter">{count}</div>;
  };

  return (
    <div className="App homepage">
      <MetaTags>
        <meta
          name="description"
          content="Arccus Inc. offers expert website design, mobile app, and AR/VR development services for businesses in the USA. 10+ years of experience. Contact us today!"
        />
        <meta
          name="keyword"
          content="mobile app development agency, application development company"
        />
        <meta
          property="og:title"
          content="Arccus is a Leading app development company in India, USA for custom on-demand delivery solutions. "
        />
        <link rel="canonical" href="https://arccusinc.com/" />
        <title>Custom Website Design & Development Services | Arccus Inc</title>
      </MetaTags>
      <section className="home-banner banner-sec">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6">
              <div className="banner-content">
                <h1>
                  Enliven your ideas with our Web Development{" "}
                  <span className="lastword">Services</span>
                </h1>
                <p>
                  Arccus is an <b>Top Web Development</b> Company centered on
                  custom web development. We are a one-stop destination for your
                  every single requisite of digital services. Request your quote
                  from all over the globe and hire your web developer today.
                </p>
                <Link to="contact-us" className="quote">
                  Request A Quote
                </Link>
              </div>
            </div>
            <div className="col-md-6">
              <div className="banner-img">
                <img
                  src={banner}
                  className="main-banner"
                  alt={"banner"}
                  width={""}
                  height={""}
                />
                <img
                  src={bannerbg}
                  className="banner-bg"
                  alt={"banner bg"}
                  width={"auto"}
                  height={"auto"}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="data-count">
        <div className="container">
          <div className="d-flex flex-wrap align-items-center counter-box">
            <div className="counter-item">
              <div className="d-flex align-items-center">
                <Counter target={10} /> <span>+</span>
              </div>
              <p>Years in business</p>
            </div>
            <div className="counter-item">
              <div className="d-flex align-items-center">
                <Counter target={300} /> <span>+</span>
              </div>
              <p>Project Completed</p>
            </div>
            <div className="counter-item">
              <div className="d-flex align-items-center">
                <Counter target={45} /> <span>+</span>
              </div>
              <p>Team Members</p>
            </div>
            <div className="counter-item">
              <div className="d-flex align-items-center">
                <Counter target={100} /> <span>+</span>
              </div>
              <p>Happy Client</p>
            </div>
          </div>
        </div>
      </section>
      <section className="services">
        <div className="container">
          <h2>
            Our Web Design & Web Development{" "}
            <span className="lastword">Services</span>
          </h2>
          <div className="d-flex flex-wrap">
            {card.map((card) => {
              return (
                <div className="card-grid">
                  <div className="card flex-column align-items-baseline service-card">
                    <div className="card-icon">
                      <img src={card.sericon} alt={"card"} loading="lazy" />
                    </div>
                    <div className="card-detail">
                      <ul>
                        <li className="card-title">{card.sertitle}</li>
                        <li className="card-text">{card.serabout}</li>
                        <li className="card-link">
                          <Link
                            to={card.link}
                            className="d-flex align-items-center gap-1"
                          >
                            View More <i class="fa-solid fa-arrow-right"></i>
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>
      <section className="why-choose">
        <div className="container">
          <div className="row">
            {/* <div className='count-card col-md-6'>
                      <div className='left-main'>
                          <div className='left-counter-card '>
                              <h3>9+</h3>
                              <p>Year in business</p>
                          </div>
                          <div className='left-counter-card '>
                              <h3>300+</h3>
                              <p>Projects made with total success</p>
                          </div>
                      </div>
                      <div className='right-main'>
                          <div className='right-counter-card '>
                              <h3>45+</h3>
                              <p>Team Menbers</p>
                          </div>
                      </div>
                  </div> */}
            <div className="why-choose-text col-md-12">
              <h2 className="text-center mb-3 pb-0">
                Why Choose <span className="lastword">Arccus</span> Inc.
              </h2>

              <h5>Your Partner in Innovative Digital Solutions</h5>
              <h6>
                At Arccus Inc., we understand the importance of industry
                expertise in delivering exceptional results. By partnering with
                us, you gain access to a team that not only understands your
                specific needs but also brings years of experience to every
                project. Our focus is on providing tailored solutions in:
              </h6>

              <div className="why-choose-main">
                <div className="why-choose-item">
                  <img src={CommitmentToExcellence} alt="img" />
                  <div className="why-choose-text">
                    <h3>Commitment to Excellence</h3>
                    <p>
                    We deliver top-notch website design and development services, ensuring high-quality outcomes that exceed industry standards.
                    </p>
                  </div>
                </div>
                <div className="why-choose-item">
                  <img src={Cimprove} alt="img" />
                  <div className="why-choose-text">
                    <h3>Continuous Improvements</h3>
                    <p>
                    Our dedication to innovation means we consistently enhance our web solutions and mobile app development to meet evolving market needs.
                    </p>
                  </div>
                </div>
                <div className="why-choose-item">
                  <img src={ThrougnUnderstandingOfClientObjectives} alt="img" />
                  <div className="why-choose-text">
                    <h3>Thorough Understanding of Client Objectives</h3>
                    <p>
                    We invest time to understand your unique business goals, tailoring our SaaS development and AR/VR development solutions accordingly.
                    </p>
                  </div>
                </div>
                <div className="why-choose-item">
                  <img src={SecurityAndAlliances} alt="img" />
                  <div className="why-choose-text">
                    <h3>Security and Alliances</h3>
                    <p>
                    Your data security is our priority. We build strong alliances to provide secure and reliable business solutions.
                    </p>
                  </div>
                </div>
                <div className="why-choose-item">
                  <img src={ResponsibleAndTrustworthly} alt="img" />
                  <div className="why-choose-text">
                    <h3>Responsible and Trustworthy</h3>
                    <p>
                    With a focus on integrity, you can trust us to be a responsible partner in your website and mobile app development journey.
                    </p>
                  </div>
                </div>
                <div className="why-choose-item">
                  <img src={CostEffectiveDevelopment} alt="img" />
                  <div className="why-choose-text">
                    <h3>Cost-Effective Development</h3>
                    <p>
                    We offer competitive pricing for our web and mobile solutions, ensuring quality and value without breaking the stakes.
                    </p>
                  </div>
                </div>
                <div className="why-choose-item">
                  <img src={AgileYetFlexibleDevelopment} alt="img" />
                  <div className="why-choose-text">
                    <h3>Agile Yet Flexible Development</h3>
                    <p>
                    Our agile approach allows us to adapt quickly, providing flexible solutions that align with your project requirements.
                    </p>
                  </div>
                </div>
                <div className="why-choose-item">
                  <img src={CollaborateWithYouEveryStepOfTheWay} alt="img" />
                  <div className="why-choose-text">
                    <h3>Collaborate with You Every Step of the Way</h3>
                    <p>
                    We work closely with you throughout the process, ensuring your vision is realized in every aspect of our development projects.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <CaseStudies />

      {/* <section className="our-partners">
        <div className="container">
          <h2 className="text-center mb-3 pb-0">
          Our <span className="lastword">Partners</span>
          </h2>
          <div className="partner-grid-main">
            <div className="partner-grid">
                <div className="partner-logo">
                    <img src={LogoIpsum} alt="partner" />
                </div>
                <div className="partner-logo">
                    <img src={LogoPartner} alt="partner" />
                </div>
                <div className="partner-logo">
                    <img src={LogoIpsum2} alt="partner" />
                </div>
                <div className="partner-logo">
                    <img src={LogoIpsum3} alt="partner" />
                </div>
                <div className="partner-logo">
                    <img src={LogoPartner2} alt="partner" />
                </div>
                <div className="partner-logo">
                    <img src={LogoIpsum2} alt="partner" />
                </div>
                <div className="partner-logo">
                    <img src={LogoIpsum} alt="partner" />
                </div>
                <div className="partner-logo">
                    <img src={LogoPartner} alt="partner" />
                </div>
                <div className="partner-logo">
                    <img src={LogoPartner2} alt="partner" />
                </div>
                <div className="partner-logo">
                    <img src={LogoIpsum3} alt="partner" />
                </div>
            </div>
          </div>
        </div>
      </section> */}

      <section className="client">
        <div className="container">
          <h2>
            Our Happy Clients <span className="lastword">Experience</span>
          </h2>
          <p className="main-content">
            Customer experience during our place, and we share it with you as a
            reference to believe in us.
          </p>
          <Client />
        </div>
      </section>
      <section className="industries">
        <h2>
          Industries We <span className="lastword">Serve</span>
        </h2>
        <div className="marquee d-block">
          {marquee.map((data, ind) => {
            return (
              <Marquee
                direction={ind === 0 ? "left" : "right"}
                gradient={false}
                pauseOnHover={true}
              >
                {data.data.map((marquee) => {
                  return (
                    <div
                      className="marquee-slider"
                      style={{ background: marquee?.color }}
                    >
                      <div className="marquee-slide">
                        <div className="marquee-img">
                          <img
                            src={marquee.images}
                            width={"50"}
                            height={"50"}
                            alt="marquee"
                            loading="lazy"
                          />
                        </div>
                        <ul>
                          <li className="industries-name">
                            {marquee.industries}
                          </li>
                          <li className="about-industries">{marquee.about}</li>
                        </ul>
                      </div>
                    </div>
                  );
                })}
              </Marquee>
            );
          })}
        </div>
      </section>
      <section className="technology">
        <div className="marquee d-block">
          <h2>
            Build Your Web Apps For{" "}
            <span className="lastword">Any Platform</span>
          </h2>
          <Marquee gradient={false} pauseOnHover={true} speed={75}>
            {technology.map((tech, ind) => {
              return (
                <div className="technology-slide ">
                  <div className="tech-head">
                    <h6>{tech.name}</h6>
                  </div>
                  <div className="technology-row">
                    {tech.tech.map((technology) => {
                      return (
                        <div className="technology-pan">
                          <img
                            src={technology.images}
                            alt={"technology"}
                            loading="lazy"
                          />
                          <h6>{technology.name}</h6>
                        </div>
                      );
                    })}
                  </div>
                </div>
              );
            })}
          </Marquee>
        </div>
      </section>
      <section className="project">
        <div className="container position-relative">
          <div className="carosoul-control">
            <h2>
              View Our Featured Client{" "}
              <span className="lastword">Projects</span>
            </h2>
          </div>

          <div className="position-class">
            <div className="project-carosoul ">
              <Project />
            </div>
          </div>
          {/* <p className='text-center mt-5'>
                  <a href='https://works.arccusinc.com' target='blank' className='quote ml-auto'>Show More</a>
                </p> */}
        </div>
      </section>

      <section className='get-in-touch'>
          <div className='container'>
               <h4>Get in Touch</h4>
               <h2>Our Office Locations</h2>
               <div className="row location-row">
               {officelocation.map( officelocation => {
                      return(
                    <div className="location col-lg-4 col-sm-12 col-md-6">
                        <img src={officelocation.image}  alt={"location"} loading='lazy' />
                        <h5>{officelocation.location}</h5>
                        <p>{officelocation.address}</p>
                        <p>{officelocation.contact}</p>
                    </div>
                    )})} 
               </div>
          </div>
      </section>

      <ContactForm />
    </div>
  );
}
export default App;
