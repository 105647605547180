import mobilityBanner from "./assets/mobilitybanner.webp";
import "./App.css";
import "react-tabs/style/react-tabs.css";
import mds from "./mobiledevelopmentservice.json";
import MobileAppDevelopment from "./MobileAppDevelopment.json";
import lifecycle from "./lifecycle.json";
import mobilityHowWeWork from "./mobilityHowWeWork.json";
import productDevelopment from "./productDevelopment.json";
import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import { scrollToTop } from "./App";
import Project from "./project.js";
import { Link } from "react-router-dom";
import technology from "./mobiletech.json";
import marquee from "./merquee.json";
import Marquee from "react-fast-marquee";
import CaseStudies from "./CaseStudies.js";
import Client from "./client.js";
import MobileDevlopmentFaq from "./MobileDevlopmentFaq.js";
import ContactForm from "./ContactForm.js";
function MobileappDevelopment() {
  useEffect(() => {
    scrollToTop();
  }, []);

   const Counter = ({ target }) => {
      const [count, setCount] = useState(0);
  
      useEffect(() => {
        const increment = target / 150;
  
        const updateCounter = () => {
          setCount((prevCount) => {
            if (prevCount < target) {
              return Math.ceil(prevCount + increment);
            } else {
              return target;
            }
          });
        };
  
        const interval = setInterval(updateCounter, 15);
  
        return () => clearInterval(interval);
      }, [target]);
  
      return <div className="counter">{count}</div>;
    };
  return (
    <div className="App Mobility">
      <MetaTags>
        <meta
          name="description"
          content="Get reliable mobile app development services at Arccus. From native to cross-platform solutions, we help businesses in India & the USA succeed. Contact us!"
        />
        <meta
          name="keyword"
          content="mobile app developer, mobile app development, mobile application development"
        />
        <meta
          property="og:title"
          content="Mobile App Development Company in India, USA"
        />
        <link
          rel="canonical"
          href="https://arccusinc.com/mobile-app-development"
        />
        <title>Leading Mobile App Development Company | Arccus Inc</title>
      </MetaTags>

      <section className=" banner-sec mobility">
        <div className="container">
          <div className="row align-items-center">
            {/* <div className='col-md-6'>
                    <div className='banner-img text-center'>
                    <img src={mobilityBanner} alt={"mobility banner"}  />
                    </div>
                </div> */}
            <div className="col-12">
              <div className="banner-content text-center">
                <h1>
                  Mobile App Development <br />{" "}
                  <span className="txt-with-bg">Solutions</span>
                </h1>
                <p>
                  Creating mobile experiences that drive your business forward.
                </p>

                <Link to="/contact-us" className="quote">
                  Contact Us
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="development-service">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <h2 className="text-start pb-0">
                Your Trusted Mobile App Development Company{" "}
                <span className="lastword">in USA</span>
              </h2>
            </div>
            <div className="col-md-6">
              <p>
                As a leading mobile app development company, we are passionate
                about crafting custom mobile applications that elevate
                businesses to new heights. Based in the USA and India, our
                skilled team brings a wealth of experience in developing
                innovative solutions for iOS, Android, and cross-platform needs.
                We focus on creating apps that not only perform seamlessly but
                also provide a delightful user experience. Whether you aim to
                enhance customer interaction or improve operational efficiency,
                we’re here to transform your ideas into powerful mobile
                solutions that drive success. Let's innovate together!
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="data-count">
        <div className="container">
          <div className="d-flex flex-wrap align-items-center counter-box">
            <div className="counter-item">
              <div className="d-flex align-items-center">
                <Counter target={10} /> <span>+</span>
              </div>
              <p>Years in business</p>
            </div>
            <div className="counter-item">
              <div className="d-flex align-items-center">
                <Counter target={300} /> <span>+</span>
              </div>
              <p>Project Completed</p>
            </div>
            <div className="counter-item">
              <div className="d-flex align-items-center">
                <Counter target={45} /> <span>+</span>
              </div>
              <p>Team Members</p>
            </div>
            <div className="counter-item">
              <div className="d-flex align-items-center">
                <Counter target={100} /> <span>+</span>
              </div>
              <p>Happy Client</p>
            </div>
          </div>
        </div>
      </section>

      <section className="MobileDevelopmentService">
        <div className="container">
          <h2 className="line-bottom">
            Our Mobile App Development{" "}
            <span className="lastword">Services</span>
          </h2>
          <div className="row">
            {mds.map((mds) => {
              return (
                <div className="col-md-6 text-center">
                  <div className="mds-box">
                    <div className="top-content">
                      <h3>{mds.title}</h3>
                      <div className="d-flex serviceIconRow">
                        {mds.images.map((ps) => {
                          return <img src={ps} alt={"service"} />;
                        })}
                      </div>
                    </div>
                    <p>{mds.content}</p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>

      <section className="Product-Development_">
        <div className="container">
          <h2 className="line-bottom">
            Our Process:{" "}
            <span className="lastword">From Concept to Launch</span>
          </h2>
          <div className=" row">
            {productDevelopment.map((productDevelopment) => {
              return (
                <div className="col-lg-3 col-sm-6 Produce-Development-Step position-relative left-shadow">
                  <div className="product-dev">
                    <img
                      src={productDevelopment.icon}
                      alt={"product development"}
                    />
                    <h3>{productDevelopment.title}</h3>
                    <p>{productDevelopment.content}</p>
                    <img
                      src={productDevelopment.image}
                      className="aerrow-img"
                      alt={"aerrow"}
                    />
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>

      <section className="why-choose-for-mad">
        <div className="container">
          <h2 className="line-bottom">
            Why Choose <span className="lastword">Arccus Inc.</span> For Mobile
            App Development?{" "}
          </h2>
          <div className="row">
            {MobileAppDevelopment.map((MobileAppDevelopment) => {
              return (
                <div className="col-md-4 text-center">
                  <div className="mad-card">
                    <img
                      src={MobileAppDevelopment.icon}
                      alt={"icon"}
                      loading="lazy"
                    />
                    <h3>{MobileAppDevelopment.title}</h3>
                    <p>{MobileAppDevelopment.content}</p>
                  </div>
                </div>
              );
            })}
          </div>
          <div className="text-center mt-4">
            <Link to="/contact-us" className="quote">
              Consult Now!
            </Link>
          </div>
        </div>
      </section>

      <CaseStudies />

      <section className="how-we-work">
        <div className="container">
          <h2 className="line-bottom">
            How We <span className="lastword">Work </span>
          </h2>
          <div className="row">
            {mobilityHowWeWork.map((mobilityHowWeWork) => {
              return (
                <div className="col-md-6">
                  <div className="mobility-HowWeWork">
                    <img
                      src={mobilityHowWeWork.icon}
                      alt={"mobility"}
                      loading="lazy"
                    />
                    <h3>{mobilityHowWeWork.title}</h3>
                    <p>{mobilityHowWeWork.content}</p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>

      <section className="How-It-Work">
        <div className="container">
          <h2 className="line-bottom">
            Full Mobile App Development{" "}
            <span className="lastword">Lifecycle</span>
          </h2>
          {lifecycle.map((lifecycle) => {
            return (
              <div className="row how-it-work-row align-items-center">
                <div className="col-md-6 text-center">
                  <img src={lifecycle.image} alt={"lifecycle"} loading="lazy" />
                </div>
                <div className="col-md-6">
                  <h3>{lifecycle.MainHead}</h3>
                  <h4>{lifecycle.SubHead}</h4>
                  <p>{lifecycle.text}</p>
                </div>
              </div>
            );
          })}
        </div>
      </section>

      <section className="technology">
        <div className="marquee d-block">
          <div className="container">
            <h2 className="mb-3 pb-0">
              Technologies We Use for{" "}
              <span className="lastword">Mobile App Development</span>
            </h2>
            <p className="text-center">
              At Arccus Inc., we are committed to innovation and excellence in
              mobile app development. We harness the latest technologies to
              ensure our solutions achieve top-notch performance and usability.
              Our expertise spans both native app development for specific
              platforms and cross-platform solutions that maximize reach. By
              utilizing frameworks such as React Native and Flutter, we create
              applications that not only look great but also function seamlessly
              across devices. Our dedicated team is focused on turning your
              vision into reality, delivering high-quality apps that engage
              users and drive business success.
            </p>
          </div>
          <Marquee gradient={false} pauseOnHover={true} speed={75}>
            {technology.map((tech, ind) => {
              return (
                <div className="technology-slide ">
                  <div className="tech-head">
                    <h6>{tech.name}</h6>
                  </div>
                  <div className="technology-row">
                    {tech.tech.map((technology) => {
                      return (
                        <div className="technology-pan">
                          <img
                            src={technology.images}
                            alt={"technology"}
                            loading="lazy"
                          />
                          <h6>{technology.name}</h6>
                        </div>
                      );
                    })}
                  </div>
                </div>
              );
            })}
          </Marquee>
        </div>
      </section>

      <section className="industries">
        <h2>
          Industries We <span className="lastword">Serve</span>
        </h2>
        <div className="marquee d-block">
          {marquee.map((data, ind) => {
            return (
              <Marquee
                direction={ind === 0 ? "left" : "right"}
                gradient={false}
                pauseOnHover={true}
              >
                {data.data.map((marquee) => {
                  return (
                    <div
                      className="marquee-slider"
                      style={{ background: marquee?.color }}
                    >
                      <div className="marquee-slide">
                        <div className="marquee-img">
                          <img
                            src={marquee.images}
                            width={"50"}
                            height={"50"}
                            alt="marquee"
                            loading="lazy"
                          />
                        </div>
                        <ul>
                          <li className="industries-name">
                            {marquee.industries}
                          </li>
                          <li className="about-industries">{marquee.about}</li>
                        </ul>
                      </div>
                    </div>
                  );
                })}
              </Marquee>
            );
          })}
        </div>
      </section>

      <section className="client">
        <div className="container">
          <h2>
            Client Success <span className="lastword">Stories</span>
          </h2>
          <p className="main-content">
            Client experience during our place, and we share it with you as a
            reference to believe in us.
          </p>
          <Client />
        </div>
      </section>

      <MobileDevlopmentFaq />

      <section className="toward-greatness text-center">
        <div className="container">
          <h2 className="line-bottom">
            Refine your Vision and push it toward{" "}
            <span className="lastword">greatness</span>
          </h2>
          <Link to="/contact-us" className="quote">
            Contact Us
          </Link>
        </div>
      </section>

      <section className="project">
        <div className="container position-relative">
          <div className="carosoul-control">
            <h2>
              View Our Featured Client{" "}
              <span className="lastword">Projects</span>
            </h2>
          </div>

          <div className="position-class">
            <div className="project-carosoul ">
              <Project />
            </div>
          </div>
          {/* <p className='text-center mt-5'>
                  <a href='https://works.arccusinc.com' target='blank' className='quote ml-auto'>Show More</a>
                </p> */}
        </div>
      </section>

      <ContactForm />
    </div>
  );
}

export default MobileappDevelopment;
